<template>
  <div
    class="card card-custom card-sticky"
    id="sticky"
    v-bind:class="{ fixed: isFixed }"
  >
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Preview
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">
          Icone e Banner
        </span>
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body " id="sticky-body" ref="sticky-body">
      <component
        v-bind:is="currentType"
        :configs="configs"
        :cookies="cookies"
      ></component>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
export default {
  name: "preview-banner",
  props: ["account", "configs", "cookies"],
  data() {
    return {
      scroll: null,
      types: {
        box: () => import("./types/box"),
        test: () => import("./types/test")
      }
    };
  },
  created() {
    window.addEventListener("scroll", () => {
      let scroll = this.makeScroll();
      this.scroll = scroll[1];
    });
  },
  computed: {
    currentType() {
      let type = this.configs.type;
      return this.types[type];
    },
    isFixed() {
      return this.scroll > 318 && window.innerWidth > 1199;
    }
  },
  methods: {
    html() {
      console.log("HTML", document.getElementById("consensuBox"));
    },
    makeScroll() {
      if (window.pageYOffset != undefined) {
        return [pageXOffset, pageYOffset];
      } else {
        var sx,
          sy,
          d = document,
          r = d.documentElement,
          b = d.body;
        sx = r.scrollLeft || b.scrollLeft || 0;
        sy = r.scrollTop || b.scrollTop || 0;
        return [sx, sy];
      }
    }
  }
};
</script>
<style>
.fixed {
  position: fixed;
  top: 90px;
}
</style>
