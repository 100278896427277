<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12  text-uppercase">
        <h5 class="font-weight-bold mb-6">Icone</h5>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-3 col-lg-3 col-form-label">Cor Icone</label>
      <div class="col-lg-3 col-xl-3">
        <v-swatches
          v-model="configs.style.iconColor"
          show-fallback
          popover-x="left"
        ></v-swatches>
      </div>

      <label class="col-xl-3 col-lg-3 col-form-label">Cor de fundo</label>
      <div class="col-lg-3 col-xl-3">
        <v-swatches
          v-model="configs.style.iconBackground"
          show-fallback
          popover-x="left"
        ></v-swatches>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-xl-3 col-lg-3 col-form-label">Cor da borda</label>
      <div class="col-lg-3 col-xl-3">
        <v-swatches
          v-model="configs.style.iconBorder"
          show-fallback
          popover-x="left"
        ></v-swatches>
      </div>
      <label class="col-xl-3 col-lg-3 col-form-label">Cor da sombra</label>
      <div class="col-lg-3 col-xl-3">
        <v-swatches
          v-model="configs.style.iconShadow"
          show-fallback
          popover-x="left"
        ></v-swatches>
      </div>
    </div>

    <div class="form-group row"></div>
  </div>
</template>

<script>
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
export default {
  name: "form-icon",
  props: ["configs"],
  components: {
    VSwatches
  }
};
</script>

<style></style>
