<template>
  <div class="card card-custom card-stretch">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Configurações
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Banner, Estilo, Textos e Links</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="button"
          class="btn btn-success mr-2"
          @click.prevent="save"
        >
          Salvar
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <types :configs="configs"></types>
        <div class="separator separator-dashed my-5"></div>
        <icon :configs="configs"></icon>
        <div class="separator separator-dashed my-5"></div>
        <colors :configs="configs"></colors>
        <div class="separator separator-dashed my-5"></div>
        <texts :configs="configs"></texts>
        <div class="separator separator-dashed my-5"></div>

        <links :configs="configs"></links>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
    <div class="card-footer d-flex " id="saveBtn">
      <button type="button" class="btn btn-success mr-2" @click.prevent="save">
        Salvar
      </button>
    </div>
  </div>
</template>

<script>
import types from "./types";
import texts from "./texts";
import colors from "./colors";
import icon from "./icon";
import links from "./links";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  name: "form-banner",
  props: ["account", "configs"],
  components: {
    types,
    texts,
    colors,
    icon,
    links
  },
  methods: {
    async save() {
      let token = await ApiService.generateToken({ account: this.account._id });
      ApiService.setHeader(token);
      this.account.steps.banner = true;
      ApiService.put(`account/${this.account._id}`, this.account).then(
        result => {
          console.log(result);
        }
      );
      ApiService.put(`consent/${this.account._id}`, {
        account: this.account._id,
        configs: this.configs
      })
        .then(({ data }) => {
          Swal.fire({
            title: "Deu Certo!",
            text: "Configurações foram registradas com sucesso!",
            icon: "success",
            heightAuto: false
          }).then(() => {
            this.$router.push(`/sites/${this.account._id}/details`);
          });
          console.log("DATA", data);
        })
        .catch(reponse => {
          console.log("DATA ERROR", reponse);
        });
    }
  }
};
</script>

<style>
select.form-control {
  text-transform: uppercase;
}
.vue-swatches__trigger {
  border: 1px solid #e4e6ef;
}

#saveBtn {
  justify-content: flex-end;
}
</style>
