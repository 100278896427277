<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12  text-uppercase">
        <h4 class="font-weight-bold mb-6">Textos</h4>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-2 col-lg-2 col-form-label">Fonte</label>
      <div class="col-lg-10 col-xl-10">
        <input
          class="form-control form-control-lg form-control-solid"
          type="text"
          v-model="configs.style.font"
          :disabled="isDisabled"
        />
      </div>
    </div>

    <div class="form-group row">
      <label class="col-xl-2 col-lg-2 col-form-label">Tamanho Título</label>
      <div class="col-lg-4 col-xl-4">
        <input
          class="form-control form-control-lg form-control-solid size"
          type="text"
          v-model="configs.style.fontSize"
          :disabled="isDisabled"
        />
      </div>
      <label class="col-xl-2 col-lg-2 col-form-label">Tamanho Descrição</label>
      <div class="col-lg-4 col-xl-4">
        <input
          class="form-control form-control-lg form-control-solid size"
          type="text"
          v-model="configs.style.fontSizeDesc"
          :disabled="isDisabled"
        />
      </div>
    </div>

    <!-- AUTORIZAÇÃO -->
    <div class="row">
      <div class="col-lg-12 col-xl-12 ">
        <h6 class="mt-4 mb-4">Autorização Total</h6>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-xl-2 col-lg-2 col-form-label">Título</label>
      <div class="col-lg-10 col-xl-10">
        <input
          class="form-control form-control-lg form-control-solid"
          type="text"
          v-model="configs.accept.title"
          :disabled="isDisabled"
        />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-2 col-lg-2 col-form-label">Descrição</label>
      <div class="col-lg-10 col-xl-10">
        <textarea
          rows="3"
          class="form-control form-control-lg form-control-solid"
          type="text"
          v-model="configs.accept.description"
          :disabled="isDisabled"
        />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-2 col-lg-2 col-form-label">Botão</label>
      <div class="col-lg-10 col-xl-10">
        <input
          class="form-control form-control-lg form-control-solid"
          type="text"
          v-model="configs.buttons.accepted"
          :disabled="isDisabled"
        />
      </div>
    </div>
    <!-- END AUTORIZAÇÃO -->

    <!-- PRINCIPAL -->
    <div class="row">
      <div class="col-lg-12 col-xl-12 ">
        <h6 class="mt-4 mb-4">Personalizado</h6>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-xl-2 col-lg-2 col-form-label">Mensagem</label>
      <div class="col-lg-10 col-xl-10">
        <input
          class="form-control form-control-lg form-control-solid"
          type="text"
          v-model="configs.message.title"
          :disabled="isDisabled"
        />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-2 col-lg-2 col-form-label">Descrição</label>
      <div class="col-lg-10 col-xl-10">
        <textarea
          rows="3"
          class="form-control form-control-lg form-control-solid"
          type="text"
          v-model="configs.message.description"
          :disabled="isDisabled"
        />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-2 col-lg-2 col-form-label">Botão</label>
      <div class="col-lg-10 col-xl-10">
        <input
          class="form-control form-control-lg form-control-solid"
          type="text"
          v-model="configs.buttons.custom"
          :disabled="isDisabled"
        />
      </div>
    </div>
    <!-- END PRINCIPAL -->

    <!-- AUTORIZAÇÃO -->
    <div class="row">
      <div class="col-lg-12 col-xl-12 ">
        <h6 class="mt-4 mb-4">Lista Personalizado</h6>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-xl-2 col-lg-2 col-form-label">Título</label>
      <div class="col-lg-10 col-xl-10">
        <input
          class="form-control form-control-lg form-control-solid"
          type="text"
          v-model="configs.custom.title"
          :disabled="isDisabled"
        />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-2 col-lg-2 col-form-label">Descrição</label>
      <div class="col-lg-10 col-xl-10">
        <textarea
          rows="3"
          class="form-control form-control-lg form-control-solid"
          type="text"
          v-model="configs.custom.description"
          :disabled="isDisabled"
        />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-2 col-lg-2 col-form-label">Informação Lista</label>
      <div class="col-lg-10 col-xl-10">
        <textarea
          rows="3"
          class="form-control form-control-lg form-control-solid"
          type="text"
          v-model="configs.custom.info"
          :disabled="isDisabled"
        />
      </div>
    </div>

    <div class="form-group row">
      <label class="col-xl-2 col-lg-2 col-form-label">Botão Salvar</label>
      <div class="col-lg-10 col-xl-10">
        <input
          class="form-control form-control-lg form-control-solid"
          type="text"
          v-model="configs.buttons.save"
          :disabled="isDisabled"
        />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-2 col-lg-2 col-form-label">Botão Voltar</label>
      <div class="col-lg-10 col-xl-10">
        <input
          class="form-control form-control-lg form-control-solid"
          type="text"
          v-model="configs.buttons.back"
          :disabled="isDisabled"
        />
      </div>
    </div>
    <!-- END AUTORIZAÇÃO -->
  </div>
</template>

<script>
import ACL from "@/mixins/acl";

export default {
  name: "form-texts",

  props: ["configs"],

  mixins: [ACL],

  computed: {
    isDisabled() {
      return !this.check("customize");
    }
  }
};
</script>

<style>
.size {
  font-size: 12px;
}
</style>
