<template>
  <div class="row">
    <div class="col-xl-6">
      <formBanner :account="account" :configs="configs"></formBanner>
    </div>
    <div class="col-xl-6">
      <previewBanner
        :account="account"
        :configs="configs"
        :cookies="cookies"
      ></previewBanner>
    </div>
  </div>
</template>

<script>
import formBanner from "./comps/form/index";
import previewBanner from "./comps/preview";
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "SiteBanner",

  props: {
    account: { type: Object, required: true }
  },

  components: {
    formBanner,
    previewBanner
  },

  data() {
    return {
      consent: {},
      cookies: [],
      configs: {
        theme: "default",
        type: "box",
        position: "bottom-left",
        style: {
          font: `"Helvetica Neue", sans-serif`,
          fontSize: "2.1em",
          fontSizeDesc: "1em",
          formHeight: "400px",
          formWidth: "800px",
          iconBackground: "#f24518",
          iconColor: "#fff",
          iconBorder: "#f83302",
          iconShadow: "#383838",
          primaryColor: "#f24518",
          secondaryColor: "#383838",
          listColor: "#c3c3c3",
          scrollBar: "#c3c3c3"
        },
        message: {
          title: "Controle sua Privacidade",
          description:
            "Nós utilizamos os cookies para personalizar anúncios, gera dados estatísticos e garantir que você tenha a melhor experiência no site."
        },
        accept: {
          title: "Autorização",
          description: "Eu aceito o uso de todos os cookies."
        },
        custom: {
          title: "Personalizado",
          description:
            "Você pode escolher o que deseja compartilhar com a gente.",
          info: "Quem pode usar seus cookies?"
        },
        privacy: {
          text: "Política de privacidade",
          url: "https://privacy"
        },
        terms: {
          text: "Termos de uso",
          url: "https://terms"
        },
        optOut: {
          text: "Optout",
          url: "https://optout"
        },
        cookies: {
          text: "Cookies",
          url: "https://cookies"
        },
        buttons: {
          accepted: "Aceitar",
          custom: "Personalizar",
          back: "Voltar",
          save: "Salvar"
        }
      }
    };
  },

  mounted() {
    this.getConsent();
    this.getCookiesActives();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profile Detail" }]);
  },

  methods: {
    getConsent() {
      ApiService.query(`consent/${this.$route.params.id}`)
        .then(({ data }) => {
          // this.$set(this, "configs", data[0].configs);
          if (!data.configs.cookies) {
            data.configs.cookies = {
              text: "",
              url: ""
            };
          }
          this.configs = data.configs;
        })
        .catch(() => {
          //console.log("DATA ERROR", response);
        });
    },

    getCookiesActives() {
      ApiService.query(`cookies/actives/${this.$route.params.id}`)
        .then(({ data }) => {
          // this.$set(this, "configs", data[0].configs);
          this.cookies = data;
        })
        .catch(() => {
          //console.log("DATA ERROR", response);
        });
    }
  }
};
</script>

<style></style>
