<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12  text-uppercase">
        <h5 class="font-weight-bold mb-6">Tipo</h5>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-2 col-lg-2 col-form-label">Tipo</label>
      <div class="col-lg-4 col-xl-4">
        <select
          class="form-control form-control-lg form-control-solid"
          v-model="configs.type"
        >
          <option v-for="type in types" :key="type" :value="type">{{
            type
          }}</option>
        </select>
      </div>
      <label class="col-xl-2 col-lg-2 col-form-label">Posição</label>
      <div class="col-lg-4 col-xl-4">
        <select
          class="form-control form-control-lg form-control-solid"
          v-model="configs.position"
        >
          <option
            v-for="position in positions"
            :key="position.value"
            :value="position.value"
            >{{ position.name }}</option
          >
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "form-types",
  props: ["configs"],
  data() {
    return {
      types: ["box"],
      positions: [
        // { name: "TOPO ESQUERDA", value: "top-left" },
        // { name: "TOPO CENTRO", value: "top-center" },
        // { name: "TOPO DIREITA", value: "top-right" },
        { name: "RODAPÉ-ESQUERDA", value: "bottom-left" },
        // { name: "RODAPÉ CENTRO", value: "bottom-center" },
        { name: "RODAPÉ-DIREITA", value: "bottom-right" }
      ]
    };
  }
};
</script>

<style></style>
