<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12  text-uppercase">
        <h5 class="font-weight-bold mt-10 mb-6">Links</h5>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-xl-3 col-lg-3 col-form-label"
        >Política de privacidade</label
      >
      <div class="col-lg-9 col-xl-9">
        <div class="input-group input-group-lg input-group-solid">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="flaticon2-website"></i>
            </span>
          </div>

          <template v-if="isDisabled('privacy')">
            <input
              type="text"
              class="form-control form-control-lg form-control-solid"
              :placeholder="$t('G.PLACEHOLDER.DISABLED')"
              disabled
            />
          </template>
          <template v-else>
            <input
              type="text"
              class="form-control form-control-lg form-control-solid"
              v-model="configs.privacy.url"
              placeholder="'https://******.***.**/privacy.html'"
            />
          </template>
        </div>
        <span class="form-text text-muted"
          >Adicione o link com suas políticas de privacidade</span
        >
      </div>
    </div>

    <div class="form-group row">
      <label class="col-xl-3 col-lg-3 col-form-label">Termos de uso</label>
      <div class="col-lg-9 col-xl-9">
        <div class="input-group input-group-lg input-group-solid">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="flaticon2-website"></i>
            </span>
          </div>
          <template v-if="isDisabled('terms')">
            <input
              type="text"
              class="form-control form-control-lg form-control-solid"
              :placeholder="$t('G.PLACEHOLDER.DISABLED')"
              disabled
            />
          </template>
          <template v-else>
            <input
              type="text"
              class="form-control form-control-lg form-control-solid"
              v-model="configs.terms.url"
              placeholder="https://******.***.**/terms.html"
            />
          </template>
        </div>
        <span class="form-text text-muted"
          >Adicione o link com os seus termos de uso.</span
        >
      </div>
    </div>

    <div class="form-group row">
      <label class="col-xl-3 col-lg-3 col-form-label">Opt-out</label>
      <div class="col-lg-9 col-xl-9">
        <div class="input-group input-group-lg input-group-solid">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="flaticon2-website"></i>
            </span>
          </div>
          <template v-if="isDisabled('optOut')">
            <input
              type="text"
              class="form-control form-control-lg form-control-solid"
              :placeholder="$t('G.PLACEHOLDER.DISABLED')"
              disabled
            />
          </template>
          <template v-else>
            <input
              type="text"
              class="form-control form-control-lg form-control-solid"
              v-model="configs.optOut.url"
              placeholder="https://******.***.**/optout.html"
            />
          </template>
        </div>
        <span class="form-text text-muted"
          >Adicione o link com para o seu formulário de optout.</span
        >
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-3 col-lg-3 col-form-label"
        >Política de cookies</label
      >
      <div class="col-lg-9 col-xl-9">
        <div class="input-group input-group-lg input-group-solid">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="flaticon2-website"></i>
            </span>
          </div>
          <template v-if="isDisabled('privacyCookies')">
            <input
              type="text"
              class="form-control form-control-lg form-control-solid"
              :placeholder="$t('G.PLACEHOLDER.DISABLED')"
              disabled
            />
          </template>
          <template v-else>
            <input
              type="text"
              class="form-control form-control-lg form-control-solid"
              v-model="configs.cookies.url"
              placeholder="https://******.***.**/cookies.html"
            />
          </template>
        </div>
        <span class="form-text text-muted"
          >Adicione o link com suas políticas de cookies</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import ACL from "@/mixins/acl";

export default {
  name: "form-links",

  props: ["configs"],

  mixins: [ACL],

  methods: {
    isDisabled(key) {
      return !this.check(key);
    }
  },
  computed: {
    plan() {
      return this.$store.getters.currentUser.plan;
    }
  }
};
</script>

<style></style>
