<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12  text-uppercase">
        <h5 class="font-weight-bold mb-6">Banner</h5>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-3 col-lg-3 col-form-label">Cor Primária</label>
      <div class="col-lg-3 col-xl-3">
        <v-swatches
          v-model="configs.style.primaryColor"
          show-fallback
          popover-x="left"
        ></v-swatches>
      </div>
      <label class="col-xl-3 col-lg-3 col-form-label">Cor Secundária</label>
      <div class="col-lg-3 col-xl-3">
        <v-swatches
          v-model="configs.style.secondaryColor"
          show-fallback
          popover-x="left"
        ></v-swatches>
      </div>
    </div>
  </div>
</template>

<script>
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
export default {
  name: "form-colors",
  props: ["configs"],
  components: {
    VSwatches
  }
};
</script>

<style></style>
